export default {
  title: 'حسابداری',

  types: {
    title: 'جدول حساب ها',

    labels: {
      name: 'عنوان سرفصل'
    },

    accountsLabel: {
      asset: 'دارایی',
      current_assets: 'جاری',
      cash_and_bank_assets: 'موجودی نقد و بانک',
      cash: 'صندوق',
      bank: 'بانک',
      payment_gateway: 'درگاه پرداخت',
      internet_payment_gateway: 'درگاه اینترنتی',
      pos: 'پوز',
      on_way_inventory: 'موجودی در راه',
      storeroom_inventory: 'موجودی انبار',
      other_accounts_receivable: 'سایر حساب های دریافتنی',
      waiting_to_send_inventory: 'موجودی در انتظار ارسال',
      accounts_receivable: 'حساب های دریافتنی',
      inventory: 'موجودی کالا',
      liability: 'بدهی',
      current_liabilities: 'جاری',
      user_wallet: 'کیف پول اشخاص',
      other_payment_accounts: 'سایر حساب های پرداختنی',
      accounts_payable: 'حساب های پرداختنی',
      payable_tax: 'مالیات پرداختنی',
      vat: 'ارزش افزوده',
      taxes: 'عملکرد',
      service_tax: 'خدمت',
      sale_tax: 'مالیات دریافتی',
      purchase_tax: 'مالیات پرداختی',
      equity: 'حقوق صاحبان سهام',
      capital: 'سرمایه',
      opening_capital: 'اولیه',
      purchase: 'خرید کالا',
      product_purchase: 'کالا',
      total_product_purchase: 'کل',
      return_product_purchase: 'مرجوع',
      product_purchase_discount: 'تخفیفات',
      service_purchase: 'خدمات',
      revenue: 'درآمد',
      other_revenue: 'مراکز درآمد',
      service_revenue: 'درآمد خدمات',
      sale_revenue: 'فروش',
      product_sale_revenue: 'کالا',
      total_product_sale_revenue: 'کل',
      return_product_sale_revenue: 'مرجوع',
      service_sale_revenue: 'خدمات',
      product_sale_shipping_revenue: 'حمل و نقل',
      personal_sale_revenue: 'فروش حضوری',
      total_personal_sale_revenue: 'فروش کل',
      personal_return_sale_expenses: 'برگشت از فروش',
      personal_sale_discounts_expenses: 'تخفیفات',
      internet_sale_revenue: 'فروش اینترنتی',
      total_internet_sale_revenue: 'فروش کل',
      internet_return_sale_expenses: 'برگشت از فروش',
      internet_sale_discounts_expenses: 'تخفیفات',
      telephone_sale_revenue: 'فروش تلفنی',
      total_telephone_sale_revenue: 'فروش کل',
      telephone_return_sale_expenses: 'برگشت از فروش',
      telephone_sale_discounts_expenses: 'تخفیفات',
      expenses: 'هزینه',
      other_expenses: 'سایر',
      cancel_service_expenses: 'لغو خدمات',
      personnel_expenses: 'پرسنلی',
      payroll_expenses: 'حقوق و دستمزد',
      reward_expenses: 'پاداش',
      sale_expenses: 'فروش',
      product_sale_expenses: 'کالا',
      product_sale_shipping_expenses: 'حمل و نقل',
      product_sale_shipping_expenses_received: 'دریافتی',
      product_sale_shipping_expenses_paid: 'پرداختی',
      product_sale_discount_expenses: 'تخفیفات',
      service_sale_expenses: 'خدمات',
      service_sale_discount_expenses: 'تخفیفات',
      sale_shipping_expenses: 'حمل و نقل',
      purchase_expenses: 'خرید',
      product_purchase_expenses: 'کالا',
      product_purchase_shipping_expenses: 'حمل و نقل',
      purchase_shipping_expenses: 'حمل و نقل',
      gift_sale_expenses: 'هدیه',
      default_gift_sale_expenses: 'هدیه عادی',
      sale_cooperation_expenses: 'همکاری',
      financial_expenses: 'مالی',
      wage_expenses: 'کارمزد',
      bank_wage_expenses: 'بانک',
      payment_gateway_wage_expenses: 'درگاه',
      tax_expenses: 'مالیات',
      corporate_tax_expenses: 'مالیات بر درامد',
      vat_expenses: 'مالیات بر ارزش افزوده',
      total_purchase: 'خرید کل',
      return_purchase_expenses: 'برگشت از خرید',
      purchase_discounts_expenses: 'تخفیفات',
      storeroom_expenses: 'انبار',
      storeroom_deficit: 'کسورات',
      storeroom_surplus: 'اضافات',
      cost_of_goods_bought: 'ﺑﻬﺎی ﺗﻤﺎم ﺷﺪه ﮐﺎﻻی خریداری شده',
      cost_of_goods_sold: 'ﺑﻬﺎی ﺗﻤﺎم ﺷﺪه ﮐﺎﻻی ﻓﺮوش رﻓﺘﻪ'
    },

    notifications: {
      insert: {
        success: 'سرفصل موردنظر با موفقیت ایجاد شد',
        error: 'ثبت اطلاعات سرفصل جدید با خطا همراه شد'
      },

      edit: {
        success: 'سرفصل موردنظر با موفقیت ایجاد شد',
        error: 'بروزرسانی اطلاعات سرفصل با خطا همراه شد'
      },

      delete: {
        success: 'سرفصل با موفقیت حذف شد',
        error: 'حذف سرفصل با خطا همراه شد'
      },

      parseError: {
        parent: 'سرفصل والد انتخاب نشده است',
        name: 'عنوان سرفصل وارد نشده است'
      }
    },

    confirmations: {
      delete: {
        title: 'حذف سرفصل',
        body: 'آیا از حذف سرفصل  {name} اطمینان دارید؟'
      }
    },

    insert: {
      title: 'افزودن سرفصل جدید'
    },

    edit: {
      title: 'ویرایش سرفصل'
    }
  },

  openingBalance: {
    title: 'تراز افتتاحیه',

    labels: {
      assets: 'دارایی',
      liabilities: 'بدهی',
      equity: 'سرمایه'
    },

    accountsLabel: {
      cash_and_bank_assets: 'موجودی نقد و بانک',
      bank: 'بانک',
      cash: 'صندوق',
      warehouse_inventory: 'موجودی کالا',
      accounts_receivable: 'حساب های دریافتنی',
      accounts_payable: 'حساب های پرداختنی',
      capital: 'سرمایه'
    },

    notifications: {
      locked: 'اول دوره قفل می باشد',
      unLocked: 'اول دوره قفل نمی باشد',
      lock: {
        success: 'ترازنامه با موفقیت قفل شد',
        error: 'قفل شدن ترازنامه با خطا مواجه شد'
      },
      unlock: {
        success: 'ترازنامه با موفقیت باز شد',
        error: 'باز شدن ترازنامه با خطا مواجه شد'
      }
    },

    confirmations: {
      unlock: {
        title: 'تائیدیه بازکردن تراز افتتاحیه',
        body: 'آیا از باز کزدن تراز افتتاحیه اطمینان دارید؟'
      },
      unlockRemainTime: {
        title: 'تائیدیه قفل تراز افتتاحیه',
        body: ' به دلیل احتمال وجود خطای انسانی در ثبت اول دوره، شما می توانید حداکثر تا {day} روز آینده قفل اول دوره را باز کنید و تغییرات مورد نظر خود را اعمال کنید',
        bodyToday: 'به دلیل احتمال وجود خطای انسانی در ثبت اول دوره، شما می توانید حداکثر تا پایان امروز قفل اول دوره را باز کنید و تغییرات مورد نظر خود را اعمال کنید'
      }
    }
  },

  documents: {
    title: 'لیست اسناد',

    notifications: {
      delete: {
        success: 'اطلاعات سند مورد نظر با موفقیت حذف شد',
        error: 'حذف اطلاعات سند مورد نظر با موفقیت حذف شد'
      }
    },

    document: {
      title: 'سند',
      dynamicTitle: 'سند شماره {id}',

      table: {
        header: {
          rowNumber: 'ردیف',
          description: 'شرح',
          debt: 'بدهکار',
          credit: 'بستانکار'
        }
      }
    },

    print: {
      cost: {
        costReceipt: 'رسید هزینه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          account: 'به سرفصل ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payed: 'پرداخت گردید'
        }
      },

      receive: {
        receiveReceipt: 'رسید دریافت وجه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',
        transactionId: 'شماره تراکنش:',
        paymentConfirm: 'تاییدیه پرداخت:',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          payee: 'از آقا/خانم ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payed: 'دریافت گردید'
        }
      },

      payment: {
        paymentReceipt: 'رسید پرداخت وجه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          payee: 'به آقا/خانم ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payed: 'پرداخت گردید'
        }
      },

      internalTransfer: {
        internalTransferReceipt: 'رسید انتقالی خزانه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payee: 'به ',
          payed: 'پرداخت گردید'
        }
      }
    },

    list: {
      title: 'لیست اسناد',

      table: {
        header: {
          id: 'شماره سند',
          createdAt: 'تاریخ',
          creator: 'ایجاد کننده',
          description: 'مستندات',
          type: 'نوع'
        },

        filters: {
          type: {
            all: 'همه'
          }
        }
      },

      confirmation: {
        deleteDocument: {
          title: 'تائیدیه حذف سند',
          body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
        }
      },

      types: {
        manual: 'دستی',
        receive: 'دریافت',
        payment: 'پرداخت',
        withdrawal: 'برداشت',
        internal_fund_transfer: 'انتقال',
        revenue: 'درامد',
        cost: 'هزینه',
        beginning_inventory: 'اول دوره',
        payroll: 'حقوق',
        purchase: 'خرید',
        return_purchase: 'مرجوع خرید',
        sale: 'فروش',
        return_sale: 'مرجوع فروش',
        warehouse_receipt: 'رسید انبار',
        warehouse_requisition: 'خروج انبار',
        inventory_transfer: 'انتقالی انبار',
        inventory_deficit: 'کسورات انبار',
        storeroom_surplus: 'اضافات انبار',
        sale_cooperation: 'همکاری در فروش'
      }
    },

    insert: {
      title: 'افزودن سند',
      dynamicTitle: 'افزودن سند دریافت/پرداخت',

      labels: {
        documentType: 'نوع سند',
        receive: 'دریافت',
        payment: 'پرداخت',
        paymentTo: 'پرداخت به',
        receiveFrom: 'دریافت از',
        withdrawalFrom: 'برداشت از',
        depositTo: 'واریز به',
        price: 'مبلغ',
        cashBox: 'صندوق',
        bank: 'بانک',
        depositType: 'نوع واریز',
        choose: 'انتخاب کنید',
        pos: 'POS',
        cardToCard: 'کارت به کارت',
        shaba: 'شبا'
      },

      notifications: {

        receive: {
          insert: {
            success: 'سند دریافت با موفقیت ایجاد شد',
            error: 'ایجاد سند دریافت با خطا همراه شد'
          }
        },

        payment: {
          insert: {
            success: 'سند پرداخت با موفقیت ایجاد شد',
            error: 'ایجاد سند پرداخت با خطا همراه شد'
          }
        },

        parseError: {
          user: 'کاربر انتخاب نشده است',
          cashBox: 'صندوق انتخاب نشده است',
          bank: 'بانک انتخاب نشده است',
          price: 'مبلغ وارد نشده است',
          depositTo: 'واریز به بانک یا صندوق مشخص نشده است',
          depositType: 'نوع واریز مشخص نشده است',
          withdrawalFrom: 'برداشت از بانک یا صندوق مشخص نشده است'
        }
      }
    }
  },

  taxes: {
    title: 'مالیات ها',

    bankTypes: {
      pos: 'POS',
      card: 'کارت به کارت',
      sheba: 'شبا'
    },

    types: {
      vat: 'مالیات بر ارزش افزوده',
      corporate: 'مالیات بر درامد'
    },

    paymentTypes: {
      customer: 'پرداخت به مشتری',
      supplier: 'پرداخت به تامین کننده'
    },

    labels: {
      all: 'همه',
      type: 'نوع',
      paymentType: 'نوع پرداخت',
      date: 'تاریخ پرداخت',
      from: 'از',
      to: 'تا',
      user: 'پرداخت به',
      payer: 'پرداخت کننده',
      price: 'مبلغ',
      description: 'توضیحات',
      transactionsReferenceId: 'شماره پیگیری',
      maxPayment: 'حداکثر قابل پرداخت',
      dynamicBalance: 'اعتبار {balance}',
      bankBalance: 'موجودی بانک',
      cashBoxBalance: 'موجودی صندوق'
    },

    placeholder: {
      date: 'اتوماتیک'
    },

    table: {
      header: {
        restore: 'بازگردانی',
        delete: 'حذف',
        to: 'به',
        from: 'از',
        payer: 'پرداخت کننده',
        type: 'نوع',
        date: 'تاریخ',
        docNumber: 'شماره سند'
      }
    },

    notifications: {
      print: {
        success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
        error: 'درخواست پرینت با خطا شد'
      }
    },

    validators: {
      dateHelp: 'ورودی باید به صورت {date} وارد شود.',
      date: 'تاریخ پرداخت درست وارد نشده است!',
      future: 'نمیتوان برای آینده پرداخت ثبت کرد'
    },

    insert: {
      title: 'مالیات جدید',

      notifications: {
        invalidType: 'نوع انتخاب نشده است',
        invalidPrice: 'مبلغ وارد شده معتبر نیست',
        invalidPayer: 'پرداخت کننده انتخاب نشده است',
        invalidPaymentType: 'نوع پرداخت مشخص نشده است',
        invalidDescription: 'توضیحات پرداخت وارد نشده است',
        price: 'مبلغ وارد شده بیشتراز حداکثر میزان قابل پرداخت است!',
        lowBalance: 'موجودی بانک یا صندوق کافی نمیباشد!',
        lowBalanceDynamicDate: 'موجودی بانک یا صندوق در تاریخ انتخاب شده کافی نمیباشد!',
        incorrect_reference_id: 'شماره تراکنش وارد شده تکراری می باشد',

        insert: {
          success: 'سند پرداخت با موفقیت ایجاد شد',
          error: 'ایجاد سند پرداخت با خطا همراه شد'
        }
      },

      suggests: {
        description: 'دریافت از {user} بابت {subject}'
      }
    },

    list: {
      title: 'مالیات ها'
    },

    trash: {
      title: 'سطل زباله مالیات ها'
    },

    document: {
      title: 'سند مالیات',
      dynamicTitle: 'سند مالیات شماره {docNumber}',

      confirmations: {
        delete: {
          title: 'تائیدیه حذف سند',
          body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
        }
      },

      notifications: {
        delete: {
          success: 'اطلاعات سند مالیات با موفقیت حذف شد',
          error: 'حذف اطلاعات سند مالیات با خطا همراه شد'
        },
        restore: {
          success: 'اطلاعات سند مالیات با موفقیت بازگردانی شد',
          error: 'بازگردانی اطلاعات سند مالیات با خطا همراه شد'
        },
      },

      labels: {
        from: 'از',
        to: 'تا',
        payer: 'پرداخت کننده/پرداخت کنندگان',
        destinations: 'دریافت کننده/دریافت کنندگان',
        details: 'سایر اطلاعات',
        creator: 'ایجادکننده',
        date: 'تاریخ ثبت',
        description: 'توضیحات'
      }
    },

    logs: {
      title: 'لاگ های مالیات',
    },
  }
}
